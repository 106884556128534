@charset "UTF-8";
/*===== BASE =====*/
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
/* HTML5 display-role reset for older browsers */
html {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

body {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  line-height: 1; }

div {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

span {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

applet {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

object {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

iframe {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

h1 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

h2 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

h3 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

h4 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

h5 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

h6 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

p {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

blockquote {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  quotes: none; }
  blockquote:before {
    content: "";
    content: none; }
  blockquote:after {
    content: "";
    content: none; }

pre {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

abbr {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

acronym {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

address {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

big {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

cite {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

code {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

del {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

dfn {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

em {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

img {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: inline-block;
  vertical-align: middle;
  max-width: 100%; }

ins {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

kbd {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

q {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  quotes: none; }
  q:before {
    content: "";
    content: none; }
  q:after {
    content: "";
    content: none; }

s {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

samp {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

small {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

strike {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

strong {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

sub {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

sup {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

tt {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

var {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

b {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

u {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

i {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

center {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

dl {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

dt {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

dd {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

ol {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  list-style: none; }

ul {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  list-style: none; }

li {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

form {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

label {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

legend {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

table {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0; }

caption {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

tbody {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

tfoot {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

thead {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

tr {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

th {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block; }

aside {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block; }

canvas {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

details {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block; }

embed {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

figure {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block; }

figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block; }

footer {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block; }

header {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block; }

hgroup {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block; }

menu {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block; }

nav {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block; }

output {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

ruby {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

section {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block; }

summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

time {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

mark {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

audio {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  *:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }

.clear {
  clear: both; }

.clearfix {
  *zoom: 1; }
  .clearfix:before {
    content: " ";
    display: table; }
  .clearfix:after {
    content: " ";
    display: table;
    clear: both; }

.chart_container {
  width: 100%;
  height: 19rem; }
  .chart_container .chartjs-render-monitor {
    max-width: 100%; }

body {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, "游ゴシック Medium", YuGothic, YuGothicM, sans-serif;
  color: #333333;
  font-size: 16px;
  line-height: 1; }
  @media screen and (max-width: 767px) {
    body {
      padding-top: 61px; } }

a {
  color: #333333;
  text-decoration: none; }

strong {
  font-weight: bold; }

input,
textarea {
  border: #E0E0E0 1px solid;
  font-size: 16px;
  padding: .75rem 1rem;
  transition: all 0.5s ease; }
  input:hover,
  textarea:hover {
    border-color: #4bccea; }
  input:focus,
  textarea:focus {
    outline: none;
    border-color: #1388a3; }

@media screen and (min-width: 768px) {
  .pcb {
    display: block; } }

@media screen and (max-width: 767px) {
  .pcb {
    display: none; } }

@media screen and (min-width: 768px) {
  .pci {
    display: inline; } }

@media screen and (max-width: 767px) {
  .pci {
    display: none; } }

@media screen and (min-width: 768px) {
  .spb {
    display: none; } }

@media screen and (max-width: 767px) {
  .spb {
    display: block; } }

@media screen and (min-width: 768px) {
  .spi {
    display: none; } }

@media screen and (max-width: 767px) {
  .spi {
    display: inline; } }

.ov_hidden {
  overflow: hidden; }

.wrapper {
  padding: 2rem;
  margin: 0 auto;
  width: calc(960px + 4rem);
  box-sizing: border-box; }
  @media screen and (max-width: 767px) {
    .wrapper {
      padding: 1rem;
      width: auto; } }

.flex_half {
  display: flex;
  margin-bottom: 1rem; }
  @media screen and (max-width: 767px) {
    .flex_half {
      flex-wrap: wrap; } }
  .flex_half > * {
    flex-basis: 50%;
    width: calc(50% - 1rem); }
    .flex_half > *:first-child {
      margin-right: 1rem; }
    .flex_half > *:last-child {
      margin-left: 1rem; }

.flex_single {
  display: flex; }
  .flex_single > * {
    flex-basis: 100% !important;
    border: none !important;
    border-bottom: 1px solid #d4f3fa; }
    .flex_single > *:nth-of-type(1) {
      padding-bottom: 0 !important; }

.link01, .link02, .list01 li a {
  transition: all 0.5s ease;
  border-bottom: 2px solid transparent;
  padding-bottom: .25rem; }
  .link01:before, .link02:before, .list01 li a:before {
    content: "▶︎";
    color: #8dc21f;
    transition: all 0.5s ease; }
  .link01:hover, .link02:hover, .list01 li a:hover {
    border-bottom-color: #faed00; }

.link02:before {
  content: "◀︎"; }

.btn01 {
  background: #188745;
  color: #ffffff;
  font-size: 16px;
  padding: 1rem;
  min-width: 17rem;
  border-radius: .25rem;
  cursor: pointer;
  transition: all 0.5s ease;
  display: inline-block;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .btn01 {
      width: 100%;
      min-width: auto; } }
  .btn01:hover {
    background: #105c2f; }

.btn02 {
  background: #F8F8F8;
  border: 1px solid #E0E0E0;
  border-radius: .25rem;
  width: 100%;
  padding: 1.25rem;
  display: inline-block;
  text-align: center;
  transition: all 0.5s ease; }
  .btn02 > i {
    margin: -1rem 0;
    display: inline-block; }
  .btn02:hover {
    background: #E0E0E0; }

.checkbox {
  position: absolute;
  display: none; }
  .checkbox + label {
    position: relative;
    display: block;
    cursor: pointer;
    vertical-align: middle;
    top: 1px;
    transition: all 0.5s ease;
    padding: .25rem;
    color: #B4B6B7; }
    .checkbox + label:before {
      content: '';
      display: inline-block;
      width: 1rem;
      height: 1rem;
      background: #ffffff;
      border: 1px solid #E0E0E0;
      margin-right: .25rem;
      vertical-align: bottom;
      transition: all 0.5s ease; }
    .checkbox + label:after {
      position: absolute;
      display: none;
      content: '';
      box-sizing: border-box;
      top: 0rem;
      left: .6rem;
      width: 0.5rem;
      height: 1rem;
      transform: rotate(45deg);
      border: solid 2px #333333;
      border-top: 0;
      border-left: 0; }
    .checkbox + label:hover {
      background: #d4f3fa;
      color: #333333; }
      .checkbox + label:hover:before {
        border-color: #333333; }
  .checkbox[disabled] {
    cursor: not-allowed; }
    .checkbox[disabled] + label {
      cursor: not-allowed;
      color: #E0E0E0; }
      .checkbox[disabled] + label:hover, .checkbox[disabled] + label:before, .checkbox[disabled] + label:after {
        cursor: not-allowed; }
      .checkbox[disabled] + label:hover:before {
        transition: all 0.5s ease;
        border: 1px solid #E0E0E0; }
      .checkbox[disabled] + label:before {
        border: 1px solid #E0E0E0; }
  .checkbox:checked + label {
    color: #333333; }
    .checkbox:checked + label:before {
      border: 1px solid #333333;
      background: #ffffff; }
    .checkbox:checked + label:after {
      display: block; }
  .checkbox:checked[disabled] + label:before {
    border: 1px solid #E0E0E0;
    background: #E0E0E0; }

.checkbox_wrapper {
  flex-basis: 100%;
  margin: .25rem 0 0 12.5rem; }
  @media screen and (max-width: 767px) {
    .checkbox_wrapper {
      margin: .25rem 0 0 0; } }

.table01, .table02, .table03, .table04 {
  border: 1px solid #d4f3fa; }
  @media screen and (max-width: 767px) {
    .table01, .table02, .table03, .table04 {
      font-size: 14px; } }
  .table01 *, .table02 *, .table03 *, .table04 * {
    border-color: #d4f3fa; }
  .table01 tr td, .table02 tr td, .table03 tr td, .table04 tr td {
    padding: .75rem 1rem;
    vertical-align: middle;
    border-bottom: 1px solid #d4f3fa;
    line-height: 1.5; }
    .table01 tr td .table_sub, .table02 tr td .table_sub, .table03 tr td .table_sub, .table04 tr td .table_sub {
      display: block;
      padding: 0 0 0 1rem;
      text-align: left; }
    .table01 tr td:first-child, .table02 tr td:first-child, .table03 tr td:first-child, .table04 tr td:first-child {
      padding-left: .5rem;
      padding-right: .5rem;
      background: #e7f9fd;
      border-color: #ffffff; }
      @media screen and (min-width: 768px) {
        .table01 tr td:first-child, .table02 tr td:first-child, .table03 tr td:first-child, .table04 tr td:first-child {
          text-align: center; } }
    @media screen and (max-width: 767px) {
      .table01 tr td, .table02 tr td, .table03 tr td, .table04 tr td {
        display: inline-block;
        width: 100%;
        padding: .5rem; }
        .table01 tr td:first-child, .table02 tr td:first-child, .table03 tr td:first-child, .table04 tr td:first-child {
          padding: .25rem .5rem; } }
  .table01 tr:last-child td:first-child, .table02 tr:last-child td:first-child, .table03 tr:last-child td:first-child, .table04 tr:last-child td:first-child {
    border-bottom: none; }

.table02, .table03 {
  font-size: 14px; }
  @media screen and (max-width: 767px) {
    .table02, .table03 {
      flex-basis: 100%;
      width: 100%;
      margin-left: 0 !important;
      margin-right: 0 !important; } }
  .table02 tr td, .table03 tr td {
    padding: .25rem .5rem;
    width: 40%; }
    .table02 tr td:first-child, .table03 tr td:first-child {
      border-top: 1px solid #d4f3fa;
      width: 20%; }
    .table02 tr td:last-child, .table03 tr td:last-child {
      border-left: 1px solid #d4f3fa; }
  .table02 thead td, .table03 thead td {
    text-align: center;
    padding: .5rem; }
    @media screen and (max-width: 767px) {
      .table02 thead td, .table03 thead td {
        display: table-cell; } }
    .table02 thead td:first-child, .table03 thead td:first-child {
      background: #1388a3;
      color: #ffffff; }
    .table02 thead td:nth-child(2), .table03 thead td:nth-child(2) {
      background: #d4f3fa; }
    .table02 thead td:nth-child(3), .table03 thead td:nth-child(3) {
      background: #F8F8F8; }
  .table02 tbody td, .table03 tbody td {
    text-align: right; }
    @media screen and (max-width: 767px) {
      .table02 tbody td, .table03 tbody td {
        display: table-cell; } }
    .table02 tbody td:first-child, .table03 tbody td:first-child {
      text-align: center;
      background: #1388a3;
      color: #ffffff; }
  @media screen and (max-width: 767px) {
    .table02, .table03 {
      border-top: none; }
      .table02:last-child thead, .table03:last-child thead {
        display: none; } }

.table03 tbody tr td {
  text-align: center;
  /*
				&:first-child {
					background: $gmBl02;
					color: $bk01;
				}
*/ }
  .table03 tbody tr td:last-child {
    padding: 0; }
  .table03 tbody tr td a {
    display: block;
    line-height: 2rem;
    transition: all 0.5s ease; }
    .table03 tbody tr td a i {
      transition: all 0.5s ease;
      position: relative;
      left: 0; }
    .table03 tbody tr td a:hover {
      color: #0e67b5;
      background: #F8F8F8; }
      .table03 tbody tr td a:hover i {
        left: -.25rem; }

.note01 {
  font-size: 11px; }

.note02 {
  color: #666666; }

.note03 {
  font-size: 14px;
  line-height: 1.75; }

.list01 {
  font-size: 14px; }
  .list01 li {
    line-height: 1.5;
    margin-bottom: .5rem; }
    .list01 li .date {
      margin-right: 1.5rem; }
      @media screen and (max-width: 767px) {
        .list01 li .date {
          margin-right: .5rem; } }
    .list01 li a {
      width: 100%;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      vertical-align: middle;
      border-bottom: none;
      padding-bottom: 0; }
      @media screen and (max-width: 767px) {
        .list01 li a {
          width: 80vw; } }
      .list01 li a:hover {
        color: #0e67b5; }
      .list01 li a:before {
        content: ""; }

.message {
  line-height: 2rem; }

.help-block {
  display: block;
  font-size: 14px;
  color: #E83517;
  border: 1px solid #E83517;
  padding: .5rem;
  margin-top: .5rem; }

.main .content .form_wrapper {
  max-width: none; }

.formset, .formset02 {
  width: 100%;
  padding-bottom: 2rem; }
  .formset ul, .formset02 ul {
    margin-bottom: 3rem; }
    @media screen and (max-width: 767px) {
      .formset ul, .formset02 ul {
        margin-bottom: 2rem; } }
  .formset li, .formset02 li {
    margin-bottom: 1.5rem; }
    .formset li:last-child, .formset02 li:last-child {
      margin-bottom: 0; }
    @media screen and (min-width: 768px) {
      .formset li .flex, .formset02 li .flex {
        display: flex;
        align-items: center; } }
  .formset label, .formset02 label {
    text-align: center; }
    @media screen and (max-width: 767px) {
      .formset label, .formset02 label {
        text-align: left;
        display: block;
        margin-bottom: .5rem; } }
    @media screen and (min-width: 768px) {
      .formset label .note01, .formset02 label .note01 {
        display: block;
        margin-top: .5rem; } }
    @media screen and (min-width: 768px) {
      .formset label + input, .formset02 label + input,
      .formset label + textarea, .formset02 label + textarea,
      .formset label + .form-group, .formset02 label + .form-group {
        margin-left: .75rem; } }
    .formset label + .form-group, .formset02 label + .form-group {
      flex: 2.67 !important; }
    .formset label + .form-group > *:first-child, .formset02 label + .form-group > *:first-child {
      width: 100%; }
  .formset .note02, .formset02 .note02 {
    margin-top: 1rem;
    font-size: 14px; }
  .formset .btn01, .formset02 .btn01 {
    text-align: center;
    margin: 0 auto;
    display: block; }

.formset02 {
  margin: 0 auto;
  padding-bottom: 1rem; }
  @media screen and (min-width: 768px) {
    .formset02 {
      width: 80%; } }
  .formset02 .flex label {
    flex: 1; }
    @media screen and (min-width: 768px) {
      .formset02 .flex label {
        text-align: right; } }
    @media screen and (max-width: 767px) {
      .formset02 .flex label {
        font-size: 14px; } }
    .formset02 .flex label + * {
      flex: 2.5; }
      @media screen and (max-width: 767px) {
        .formset02 .flex label + * {
          width: 100%; } }
  .formset02 .flex .link02 {
    top: 4.5rem;
    left: 1.5rem;
    display: inline-block;
    position: relative; }
    @media screen and (max-width: 767px) {
      .formset02 .flex .link02 {
        top: .5rem;
        left: 0; } }
  .formset02 .flex.aligns {
    align-items: flex-start; }
  .formset02 ul {
    margin-bottom: 0; }
  .formset02 .gap {
    flex: 1; }
  .formset02 .checkbox + label {
    display: inline-block; }
  .formset02 .capacity:after {
    content: "kW";
    margin-left: .25rem; }

.cheader {
  border-bottom: 1px solid #d4f3fa; }
  @media screen and (min-width: 768px) {
    .cheader {
      display: flex;
      align-items: center; } }
  .cheader h1 {
    background: #1388a3;
    color: #ffffff;
    font-size: 20px;
    text-align: center;
    padding: 0 2rem;
    height: 90px;
    line-height: 90px;
    flex: 1; }
    @media screen and (max-width: 767px) {
      .cheader h1 {
        display: none; } }
    .cheader h1 + * {
      padding: 1rem; }
      @media screen and (min-width: 768px) {
        .cheader h1 + * {
          display: flex;
          flex: 6;
          align-items: center; } }
      .cheader h1 + * > *:nth-of-type(1), .cheader h1 + * > *:nth-of-type(2) {
        flex: 2.5; }
      .cheader h1 + * > p {
        line-height: 1.5; }
        @media screen and (max-width: 767px) {
          .cheader h1 + * > p {
            font-size: 14px;
            margin-bottom: .5rem; } }
  .cheader .info {
    padding: 0; }
    @media screen and (max-width: 767px) {
      .cheader .info {
        display: flex;
        flex-wrap: wrap; } }
    .cheader .info .news {
      padding: .5rem 1rem;
      flex: auto; }
      @media screen and (max-width: 767px) {
        .cheader .info .news {
          flex-basis: 100%;
          order: 2;
          width: 100%; } }
      .cheader .info .news.list01 li {
        margin-bottom: 0; }
        @media screen and (min-width: 768px) {
          .cheader .info .news.list01 li a {
            width: 37rem; } }
        @media screen and (max-width: 767px) {
          .cheader .info .news.list01 li a {
            font-size: 14px; } }
    @media screen and (min-width: 768px) {
      .cheader .info .btn_bubble {
        flex: auto;
        width: 17%; } }
    @media screen and (max-width: 767px) {
      .cheader .info .btn_bubble {
        order: 1;
        flex-basis: 100%; } }
    .cheader .info .btn_bubble a {
      background: #E1F0F2;
      font-size: 14px;
      display: block;
      vertical-align: baseline;
      transition: all 0.5s ease;
      position: relative; }
      @media screen and (max-width: 767px) {
        .cheader .info .btn_bubble a {
          padding: .5rem; } }
      @media screen and (min-width: 768px) {
        .cheader .info .btn_bubble a {
          height: 90px;
          line-height: 90px;
          text-align: center;
          padding: 0 .5rem; }
          .cheader .info .btn_bubble a:after {
            font-size: 16px;
            content: "◀︎";
            position: absolute;
            left: -.75rem;
            top: 0;
            color: #E1F0F2;
            transition: all 0.5s ease; } }
      .cheader .info .btn_bubble a:hover {
        background: #55B7B8; }
        .cheader .info .btn_bubble a:hover:after {
          color: #55B7B8; }

.toggler {
  border: 1px solid #1388a3;
  border-radius: .25rem;
  display: flex; }
  .toggler > * {
    flex-basis: 33.4%;
    padding: .5rem;
    text-align: center;
    background: #F8F8F8;
    color: #1388a3;
    border-left: 1px solid #1388a3; }
    .toggler > *:first-child {
      border-radius: .25rem 0 0 .25rem;
      border-left: none; }
    .toggler > *:last-child {
      border-radius: 0 .25rem .25rem 0; }
    .toggler > *.active {
      background: #188745;
      color: #ffffff; }
  .toggler a {
    transition: all 0.5s ease; }
    .toggler a:hover {
      color: #fff;
      background: #8dc21f; }

.picker {
  display: flex;
  margin-bottom: 2rem; }
  @media screen and (max-width: 767px) {
    .picker {
      flex-wrap: wrap; } }
  .picker > * {
    padding: .5rem;
    border-bottom: 1px solid #333333;
    margin-bottom: 0 !important;
    display: flex;
    align-items: center;
    transition: all 0.5s ease;
    cursor: pointer;
    position: relative; }
    @media screen and (max-width: 767px) {
      .picker > * {
        width: 100%;
        font-size: 14px; } }
    @media screen and (min-width: 768px) {
      .picker > * {
        flex: 1; }
        .picker > *:first-child {
          margin-right: 1.5rem; }
        .picker > *:last-child {
          margin-left: 1.5rem; } }
    .picker > *:hover {
      background: #e7f9fd; }
    .picker > *:after {
      content: "▼";
      color: #105c2f;
      font-size: 16px;
      margin-left: 2rem;
      position: absolute;
      right: .5rem; }
    .picker > * .datepicker {
      position: relative;
      appearance: none;
      display: block;
      border: none;
      background: none;
      border-radius: 0;
      text-align: center;
      padding: .5rem 4rem .5rem .5rem;
      font-size: 20px;
      cursor: pointer;
      font-size: 20px;
      flex: 1;
      text-align: center; }
      .picker > * .datepicker:focus {
        outline: none; }
      @media screen and (max-width: 767px) {
        .picker > * .datepicker {
          font-size: 16px;
          padding: .25rem .5rem; } }
    .picker > * > span:first-of-type:before {
      content: "●";
      color: #55B7B8;
      margin-right: .25rem; }
  .picker .date02 .checkbox + label {
    padding: 0; }
    .picker .date02 .checkbox + label:before {
      margin: -3px 1rem 0 0;
      display: block; }
    .picker .date02 .checkbox + label:after {
      top: -.5rem;
      left: .35rem; }
    .picker .date02 .checkbox + label:hover {
      background-color: transparent; }
  .picker .date02 > span:first-of-type:before {
    content: "◯";
    color: #55B7B8;
    margin-right: .25rem; }

.date03 select {
  position: relative;
  appearance: none;
  display: block;
  border: none;
  background: none;
  border-radius: 0;
  border-bottom: 1px solid #333333;
  text-align: center;
  padding: .5rem 2.5rem .5rem 1rem;
  font-size: 20px;
  position: relative;
  cursor: pointer;
  transition: all 0.5s ease; }
  .date03 select::-ms-expand {
    display: none; }
  .date03 select:hover {
    background: #E1F0F2; }

.date03 label {
  position: relative; }
  .date03 label:after {
    display: block;
    content: "▼";
    color: #0B5391;
    font-size: 16px;
    position: absolute;
    left: 6rem;
    top: -2rem;
    pointer-events: none; }

.date_form {
  flex: 1 !important; }

.breadcrumb + .wrapper {
  min-height: calc(100vh - (65px + 46px + 121px)); }

.pagination_group {
  display: flex;
  align-items: center;
  margin: 2rem -1rem 0; }
  .pagination_group .prev a,
  .pagination_group .next a {
    position: relative;
    transition: all 0.5s ease;
    display: inline-block;
    padding: 0 .5rem;
    transition: all 0.5s ease; }
  .pagination_group .prev a {
    left: 0; }
    .pagination_group .prev a:hover {
      left: -.5rem; }
  .pagination_group .next a {
    right: 0; }
    .pagination_group .next a:hover {
      right: -.5rem; }
  .pagination_group .pagination {
    flex: 1;
    text-align: center;
    justify-content: space-between; }
    .pagination_group .pagination > * {
      display: inline-block;
      box-sizing: border-box;
      margin: 0 .5rem; }
    .pagination_group .pagination a {
      padding: .5rem;
      border: 1px solid transparent;
      transition: all 0.5s ease; }
      .pagination_group .pagination a:hover {
        border-color: #d4f3fa; }
    .pagination_group .pagination .active {
      font-weight: bold;
      color: #1388a3; }

.pagenav {
  display: flex;
  align-items: center;
  margin: 2rem 0 0;
  order: 99; }
  .pagenav > * {
    margin-bottom: 0 !important;
    flex-basis: 50%; }
    .pagenav > * a {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 1.5;
      transition: all 0.5s ease; }
      .pagenav > * a > span {
        padding: 1rem;
        flex: 1; }
      .pagenav > * a .date {
        display: block; }
      .pagenav > * a i {
        transition: all 0.5s ease;
        flex-basis: 24px;
        position: relative; }
      .pagenav > * a:hover {
        color: #1388a3; }
    .pagenav > *.prev {
      margin-right: .5rem; }
      .pagenav > *.prev a i {
        left: 0; }
      .pagenav > *.prev a:hover i {
        left: -.25rem; }
    .pagenav > *.next a {
      padding-left: .5rem;
      border-left: 1px solid #d4f3fa; }
      .pagenav > *.next a i {
        right: 0; }
      .pagenav > *.next a:hover i {
        right: -.25rem; }

.info_calls {
  text-align: center;
  background: #E5FCFF;
  padding: 2rem;
  display: flex;
  flex-wrap: wrap; }
  @media screen and (max-width: 767px) {
    .info_calls {
      padding: 1rem; } }
  .info_calls dl {
    line-height: 1.75;
    flex-basis: 50%;
    margin-bottom: 2rem; }
    @media screen and (max-width: 767px) {
      .info_calls dl {
        flex-basis: 100%;
        margin-bottom: 1rem;
        line-height: 1.5; } }
    .info_calls dl:last-child {
      flex-basis: 100%;
      margin-bottom: 0; }
    .info_calls dl dt {
      font-weight: bold; }
      .info_calls dl dt:before {
        content: "■";
        color: #faed00; }
      @media screen and (max-width: 767px) {
        .info_calls dl dt {
          margin-bottom: .5rem; } }
    @media screen and (min-width: 768px) {
      .info_calls dl dd ul li {
        display: flex; } }
    .info_calls dl dd ul li span:first-child {
      flex: 1; }
    .info_calls dl dd ul li span:last-child {
      flex: 2; }

.hnav {
  border-bottom: #d4f3fa 1px solid; }
  @media screen and (max-width: 767px) {
    .hnav {
      position: fixed;
      z-index: 5;
      width: 100%;
      top: 0;
      left: 0;
      background: #fff; } }
  .hnav .wrapper {
    padding: 0;
    display: flex;
    align-items: center; }
  .hnav h1 {
    flex-basis: 25%;
    text-align: left;
    padding: 0 2rem; }
    @media screen and (max-width: 767px) {
      .hnav h1 {
        flex-basis: 45%;
        padding: 1rem 1rem;
        max-width: 130px; } }
    .hnav h1 + nav {
      flex-basis: 75%;
      display: flex; }
      @media screen and (max-width: 767px) {
        .hnav h1 + nav {
          font-size: 14px; } }
      .hnav h1 + nav > * {
        display: flex;
        align-items: center; }
      .hnav h1 + nav .primal {
        flex-basis: 40%; }
        @media screen and (max-width: 767px) {
          .hnav h1 + nav .primal {
            flex-basis: 80%; } }
        .hnav h1 + nav .primal > * {
          width: 50%; }
        .hnav h1 + nav .primal a {
          font-weight: bold;
          text-decoration: none;
          border: none;
          color: #333333;
          padding: 1.5rem;
          border-left: 1px solid #d4f3fa;
          display: block;
          text-align: center; }
          @media screen and (max-width: 767px) {
            .hnav h1 + nav .primal a {
              padding: 1.5rem .5rem; } }
          .hnav h1 + nav .primal a span {
            border-bottom: 3px solid transparent;
            transition: all 0.5s ease; }
          .hnav h1 + nav .primal a:hover span {
            border-bottom-color: #faed00;
            padding-bottom: .25rem; }
          .hnav h1 + nav .primal a.current {
            background: #e7f9fd; }
            .hnav h1 + nav .primal a.current span {
              border-bottom-color: #faed00;
              padding-bottom: .25rem; }
      .hnav h1 + nav .secondary {
        flex-basis: 60%;
        padding: 0 2rem;
        border-left: 1px solid #d4f3fa;
        justify-content: space-around; }
        @media screen and (max-width: 767px) {
          .hnav h1 + nav .secondary {
            display: none;
            position: absolute;
            z-index: 10;
            background: #fff;
            width: 100%;
            flex-basis: 100%;
            left: 0;
            top: 63px;
            border-left: none;
            padding: 0;
            height: 100vh; } }
        .hnav h1 + nav .secondary a {
          font-size: 14px; }
          @media screen and (max-width: 767px) {
            .hnav h1 + nav .secondary a {
              padding: 1rem;
              border-bottom: 1px solid #faed00;
              display: block; } }
      @media screen and (min-width: 768px) {
        .hnav h1 + nav .sp_nav_toggle {
          display: none; } }
      @media screen and (max-width: 767px) {
        .hnav h1 + nav .sp_nav_toggle {
          border-left: 1px solid #d4f3fa;
          cursor: pointer;
          flex-basis: 25%;
          display: flex; }
          .hnav h1 + nav .sp_nav_toggle img {
            width: 24px;
            display: block;
            margin: 0 auto; } }

.hmessage {
  background: #a32e13;
  color: #ffffff;
  padding: .75rem 1rem;
  position: relative;
  font-size: 16px; }
  @media screen and (max-width: 767px) {
    .hmessage {
      font-size: 14px;
      line-height: 1.5; } }
  .hmessage > p:first-child {
    text-align: center;
    margin: 0 2rem; }
  .hmessage .btn_close {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -1rem;
    padding: .45rem;
    border-radius: 1rem;
    transition: all 0.5s ease;
    cursor: pointer;
    vertical-align: middle; }
    .hmessage .btn_close i {
      width: 1rem;
      height: 1rem;
      line-height: 1; }
    .hmessage .btn_close:hover {
      background-color: #48abac; }

.breadcrumb {
  font-size: 14px; }
  .breadcrumb.wrapper {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .breadcrumb li {
    display: inline;
    color: #1388a3; }
    .breadcrumb li a {
      color: #1388a3;
      transition: all 0.5s ease; }
      .breadcrumb li a:after {
        content: ">";
        margin: 0 0 0 .5rem;
        display: inline-block;
        color: #1388a3; }
      .breadcrumb li a:hover {
        color: #333333; }
        .breadcrumb li a:hover:after {
          color: #1388a3; }
  .breadcrumb + * {
    padding-top: 0; }

footer {
  background: #1388a3;
  color: #ffffff;
  font-size: 14px; }
  @media screen and (max-width: 767px) {
    footer .wrapper {
      padding: 0 0 1rem; } }
  footer .wrapper > nav {
    display: flex;
    justify-content: space-between;
    margin: 0 auto 2rem; }
    @media screen and (min-width: 768px) {
      footer .wrapper > nav {
        width: 80%; } }
    @media screen and (max-width: 767px) {
      footer .wrapper > nav {
        flex-wrap: wrap;
        margin: 0 auto 1rem; } }
    footer .wrapper > nav a {
      color: #ffffff;
      text-align: center;
      width: 100%;
      border-left: 1px solid #0e6275;
      padding: .5rem 0;
      transition: all 0.5s ease; }
      footer .wrapper > nav a:first-child {
        border-left: none; }
      footer .wrapper > nav a:hover {
        background: #10758c; }
      @media screen and (max-width: 767px) {
        footer .wrapper > nav a {
          display: block;
          flex-basis: 100%;
          padding: 1rem 1.5rem;
          border-left: none;
          border-bottom: 1px solid #d4f3fa; } }
  footer .wrapper .copyright {
    text-align: center;
    font-size: 11px;
    letter-spacing: .125rem; }
    @media screen and (max-width: 767px) {
      footer .wrapper .copyright {
        letter-spacing: .05rem;
        padding: 1rem 1rem 0; } }

.login {
  background: #F8F8F8; }
  @media screen and (max-width: 767px) {
    .login {
      padding-top: 0; } }
  @media screen and (min-width: 768px) {
    .login {
      height: 100vh;
      padding: 2rem; } }
  .login .wrapper {
    display: flex;
    justify-content: center;
    align-items: center; }
    @media screen and (max-width: 767px) {
      .login .wrapper {
        height: auto; } }
    .login .wrapper .inner header {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; }
      .login .wrapper .inner header h1 {
        order: 2;
        margin-bottom: 3.5rem;
        width: 100%;
        text-align: center; }
        @media screen and (max-width: 767px) {
          .login .wrapper .inner header h1 {
            width: 75%;
            margin-bottom: 2rem; } }
      .login .wrapper .inner header .subtitle {
        order: 1;
        letter-spacing: .25rem;
        margin-bottom: 1rem;
        width: 100%;
        text-align: center; }
        @media screen and (max-width: 767px) {
          .login .wrapper .inner header .subtitle {
            font-size: 14px; } }
    .login .wrapper .inner .message {
      text-align: center;
      margin-bottom: 2rem; }
      @media screen and (min-width: 768px) {
        .login .wrapper .inner .message {
          margin: -1.5rem 0 2rem; } }
      @media screen and (max-width: 767px) {
        .login .wrapper .inner .message {
          margin: 1rem 0;
          font-size: 16px;
          line-height: 1.5; } }
    .login .wrapper .inner .formset, .login .wrapper .inner .formset02 {
      width: 30rem;
      margin: 0 auto; }
      @media screen and (max-width: 767px) {
        .login .wrapper .inner .formset, .login .wrapper .inner .formset02 {
          width: 100%; } }
      @media screen and (min-width: 768px) {
        .login .wrapper .inner .formset label, .login .wrapper .inner .formset02 label {
          width: 6rem;
          text-align: right; } }
      .login .wrapper .inner .formset label + *, .login .wrapper .inner .formset02 label + * {
        width: calc(100% - 6rem); }
        @media screen and (max-width: 767px) {
          .login .wrapper .inner .formset label + *, .login .wrapper .inner .formset02 label + * {
            width: 100%; } }
      @media screen and (max-width: 767px) {
        .login .wrapper .inner .formset .note01, .login .wrapper .inner .formset02 .note01 {
          margin-left: .25rem; } }
      .login .wrapper .inner .formset .note02, .login .wrapper .inner .formset02 .note02 {
        text-align: right;
        width: 100%; }
  .login footer {
    text-align: center;
    height: 2rem;
    background: transparent;
    width: calc(960px + 4rem);
    margin: 0 auto; }
    @media screen and (max-width: 767px) {
      .login footer {
        width: auto;
        margin-bottom: 1rem; } }

.main .wrapper > .inner {
  border: 1px solid #d4f3fa; }

.main .content {
  display: flex;
  flex-wrap: wrap; }
  @media screen and (min-width: 768px) {
    .main .content {
      padding: 1rem; } }
  .main .content .form_wrapper > section {
    border-bottom: none !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important; }
    .main .content .form_wrapper > section:last-child {
      margin-bottom: 1rem !important; }
  .main .content > * {
    flex-basis: 100%;
    max-width: 100%; }
    @media screen and (min-width: 768px) {
      .main .content > * {
        flex-basis: 50%;
        max-width: 50%; } }
    .main .content > *:first-child {
      flex-basis: 100%;
      max-width: 100%; }
    @media screen and (min-width: 768px) {
      .main .content > *:nth-child(3) {
        padding: 0 0 1rem 1rem; } }
  .main .content .article,
  .main .content section {
    padding: 1rem; }
    @media screen and (min-width: 768px) {
      .main .content .article.w80p,
      .main .content section.w80p {
        flex-basis: 80%;
        max-width: 80%;
        margin-left: auto;
        margin-right: auto; } }
    .main .content .article header,
    .main .content section header {
      margin-bottom: 1rem; }
      @media screen and (min-width: 768px) {
        .main .content .article header,
        .main .content section header {
          display: flex;
          align-items: center; } }
      .main .content .article header h1,
      .main .content section header h1 {
        margin-bottom: 0; }
      .main .content .article header .note02,
      .main .content section header .note02 {
        font-size: 14px;
        line-height: 1.5; }
        @media screen and (min-width: 768px) {
          .main .content .article header .note02,
          .main .content section header .note02 {
            flex: 1;
            margin: 0 0 0 2rem; } }
        @media screen and (max-width: 767px) {
          .main .content .article header .note02,
          .main .content section header .note02 {
            margin-top: .5rem; } }
      .main .content .article header .btn02,
      .main .content section header .btn02 {
        padding: .75rem 2rem; }
    .main .content .article h1,
    .main .content section h1 {
      color: #21afd2;
      border-bottom: 2px solid #faed00;
      font-weight: normal;
      margin-bottom: calc(.5rem - 1px);
      padding-bottom: calc(.5rem - 1px);
      letter-spacing: .125rem;
      display: inline-block; }
      @media screen and (min-width: 768px) {
        .main .content .article h1,
        .main .content section h1 {
          font-size: 25.6px;
          margin-bottom: 2rem; } }
    .main .content .article p,
    .main .content section p {
      margin-bottom: 1.5rem; }
      .main .content .article p:last-child,
      .main .content section p:last-child {
        margin-bottom: 0; }
    .main .content .article strong,
    .main .content section strong {
      margin-right: 1rem; }
    .main .content .article .paragraphs p,
    .main .content section .paragraphs p {
      line-height: 1.75rem; }
    .main .content .article .btn01,
    .main .content section .btn01 {
      display: block;
      letter-spacing: .125rem;
      margin: 1rem auto 0; }
      @media screen and (min-width: 768px) {
        .main .content .article .btn01,
        .main .content section .btn01 {
          margin: 2rem auto 0;
          width: 17rem; } }
    .main .content .article:nth-of-type(1),
    .main .content section:nth-of-type(1) {
      border-bottom: 1px solid #d4f3fa;
      padding-bottom: 2rem;
      margin-bottom: 1rem; }
      .main .content .article:nth-of-type(1) .table01, .main .content .article:nth-of-type(1) .table02, .main .content .article:nth-of-type(1) .table03, .main .content .article:nth-of-type(1) .table04,
      .main .content section:nth-of-type(1) .table01,
      .main .content section:nth-of-type(1) .table02,
      .main .content section:nth-of-type(1) .table03,
      .main .content section:nth-of-type(1) .table04 {
        margin: 0 auto; }
        @media screen and (min-width: 768px) {
          .main .content .article:nth-of-type(1) .table01, .main .content .article:nth-of-type(1) .table02, .main .content .article:nth-of-type(1) .table03, .main .content .article:nth-of-type(1) .table04,
          .main .content section:nth-of-type(1) .table01,
          .main .content section:nth-of-type(1) .table02,
          .main .content section:nth-of-type(1) .table03,
          .main .content section:nth-of-type(1) .table04 {
            width: 80%; } }
      .main .content .article:nth-of-type(1):last-of-type,
      .main .content section:nth-of-type(1):last-of-type {
        border: none;
        padding: 1rem 1rem 0; }
    @media screen and (min-width: 768px) {
      .main .content .article:nth-of-type(2),
      .main .content section:nth-of-type(2) {
        border-right: 1px solid #d4f3fa; } }
    .main .content .article:nth-of-type(2):last-child,
    .main .content section:nth-of-type(2):last-child {
      border: none;
      padding: 1rem;
      flex-basis: 100%;
      max-width: 100%; }
      @media screen and (max-width: 767px) {
        .main .content .article:nth-of-type(2):last-child,
        .main .content section:nth-of-type(2):last-child {
          padding-top: 0; } }
    .main .content .article:nth-of-type(3),
    .main .content section:nth-of-type(3) {
      border-bottom: 1px solid #d4f3fa; }
  .main .content .article {
    display: flex;
    flex-wrap: wrap; }
    .main .content .article > * {
      flex-basis: 100%;
      max-width: 100%; }
    .main .content .article > h1 {
      order: 2; }
      .main .content .article > h1 + .date {
        order: 1;
        color: #B4B6B7;
        font-size: 14px;
        margin-bottom: 1rem; }
    .main .content .article .paragraphs {
      order: 3; }
  .main .content > div > section:nth-of-type(2) {
    border-right: none; }
    .main .content > div > section:nth-of-type(2) p {
      margin-bottom: 0; }
  .main .content .sidecol {
    padding: 1rem 1rem 1rem 1rem; }
    .main .content .sidecol > h1 {
      color: #1388a3;
      font-size: 16px;
      margin-bottom: 2rem;
      font-weight: bold; }
      @media screen and (max-width: 767px) {
        .main .content .sidecol > h1 {
          margin-bottom: 1rem; } }
    .main .content .sidecol .list01 li {
      margin-bottom: 1.5rem; }
      @media screen and (max-width: 767px) {
        .main .content .sidecol .list01 li {
          margin-bottom: .5rem; } }
      .main .content .sidecol .list01 li a {
        white-space: normal; }
        .main .content .sidecol .list01 li a .date {
          display: block; }
          @media screen and (max-width: 767px) {
            .main .content .sidecol .list01 li a .date {
              font-size: 14px; } }
  @media screen and (min-width: 768px) {
    .main .content.cols2 .article {
      flex-basis: 75%;
      max-width: 75%; } }
  @media screen and (min-width: 768px) {
    .main .content.cols2 .sidecol {
      flex-basis: 25%;
      max-width: 25%; } }
  @media screen and (max-width: 767px) {
    .main .content.cols2 .sidecol {
      background: #d4f3fa; } }
  .main .content.cols2 .sidecol .toall {
    text-align: right;
    font-size: 14px; }
    @media screen and (max-width: 767px) {
      .main .content.cols2 .sidecol .toall {
        margin-top: 1rem; } }
